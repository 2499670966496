/* #preloadedImages
{
    width: 0px;
    height: 0px;
    display: inline;
    background-image: url(./images/me-photo.png);
  } */

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* .App {
    min-height: 100vh
    background-color: #EBEBEB;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

  .section {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    text-align: center;
    min-height: 100vh;
    max-height: 100vh;
  }

  .home-body {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .body {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
  }

  .body-layout2 {
    min-height: 65vh;
    display: flex;
  }

  .body-layout2-mainbar {
    min-height: 65vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding-top: 4vh;
    padding-left: 10vh;
    padding-right: 10vh;
  }

  .body-layout2-sidebar {
    /* min-height: 65vh; */
    display: flex;
    width: 20vw;
    /* justify-content: top; */
    /* padding: 2vh; */
    margin-top: -6.6vh;
    min-height: 100vh;
  }

  .Icons-container {
    display: flex;
    flex-wrap: wrap;
    min-width: 48vh;
    /* justify-content: space-between; */
    justify-content: space-evenly;
    /* justify-content: space-between; */
    align-items: top;
  }

  .Icons-container-vertical {
    background-color: #EBEBEB;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: column;
    /* background-color: #829CD0; */
    /* padding: 2vh; */
    padding: 3vh;
    height: 100vh;
  }

  .Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8vh;
    /* background-color: red; */
  }

  .Footer {
    display: flex;
    height: 20vh;
    min-width: 100%;
    justify-content: center;
  }

  .About-Me {
  }

  .Work-Achievements {
    background-color: #20368F;
    color: #EBEBEB;
  }

  .test {
    background-color: red;
  }

  .test-2 {
    background-color: green;
  }

  .Photo-holder {
    height: 42.5vh;
    margin: 1vh;
    border-radius: 50%;
  }

  .body {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Icon {
    justify-content: center;
    height: 6vh;
    /* margin-left: 2vw;
    margin-right: 2vw; */
  }

  .Name-heading {
    font-size: calc(10px + 8vmin);
    color: #6D6D6D;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  .Name-heading-2 {
    font-size: calc(8px + 5vmin);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  .Subheading {
    font-size: calc(10px + 2vmin);
  }

  /* 404 Page */

  .Message-box {
    align-items: center;
    margin: 30px;
    border-radius: 25px;
    background: #bccce0;
    height: 40px;
    line-height: 40px;
    width: 325px;
  }

  .NotFound-header {
    background-color: #00034b;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .ghost__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    background: #00034b;
  }
  .ghost {
    position: relative;
    width: 150px;
    height: 185px;
    background: #fff;
    box-shadow: -17px 0px 0px #dbdbdb inset, 0 0 50px #5939db;
    border-radius: 100px 100px 0 0;
    animation: float 2s infinite;
  }
  .ghost__eyes {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding: 70px 0 0;
    width: 90px;
    height: 20px;
  }
  .ghost__eyes:before, .ghost__eyes:after {
    content: "";
    display: block;
    width: 15px;
    height: 25px;
    background: #00034b;
    border-radius: 50%;
  }
  .ghost__feet {
    width: 100%;
    position: absolute;
    bottom: -13px;
    display: flex;
    justify-content: space-between;
  }
  .ghost__feet-foot {
    width: 25%;
    height: 26px;
    border-radius: 50%;
    background: #fff;
  }
  .ghost__feet-foot:last-child {
    background-image: linear-gradient(to right, #fff 55%, #dbdbdb 45%);
  }
  .shadow {
    background: #000232;
    width: 150px;
    height: 40px;
    margin-top: 50px;
    border-radius: 50%;
    animation: shadow 2s infinite;
  }
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
   }
    50% {
      transform: translateY(-15px);
   }
  }
  @keyframes shadow {
    0%, 100% {
      transform: scale(1);
   }
    50% {
      transform: scale(0.9);
   }
  }

  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;


    .card {
      display: inline-block;
    }
  }

